
<template>
  <router-view/>
</template>



<script>
  
  import {mapActions} from "vuex";
  import router from "../src/router";
  import { getAuth, onAuthStateChanged } from "firebase/auth";
  import { getFirestore, serverTimestamp} from "firebase/firestore";
  import { doc, setDoc, getDoc, updateDoc, onSnapshot, deleteField, orderBy } from "firebase/firestore";
 
  import 'prevent-pull-refresh';
 
  export default {
    components: {
      
    },
    methods:{
      ...mapActions(['loadData','loadDataUser']),
       get(){
            const auth = getAuth();
            onAuthStateChanged(auth, (user) => {
            if (user) {
                const uid = user.uid;
            } else {
              if (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/Windows Phone/i) || window.innerWidth <= 780){
                setTimeout(() => {
                  //router.push('/reg/home')
                }, 2000);
                
              }else{

              }
                
             }
            });
        },

        removeaud(){
            const aud = document.getElementsByTagName('audio')
        if (aud.length >=2) {
            for(let i in aud){
                if (aud[i].tagName) {
                    aud[i].remove()
                }
              
            }
          
        }
        },

        
        
    },
    

    computed:{
      
     
    },

    beforeCreate() {

      

      if (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/Windows Phone/i) || window.innerWidth <= 780) {
            const isAndroid = navigator.userAgent.match(/Android/i)
     
            if(isAndroid){
                 // Verificar si la PWA está instalada
               const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
                const isFullscreen = window.matchMedia('(display-mode: fullscreen)').matches;
                const isIOSStandalone = navigator.standalone === true;
                 const playStore = localStorage.getItem('playStore')
                // Si no está instalada, redirigir a la Play Store o App Store
                if (!isStandalone && !isFullscreen && !isIOSStandalone  || !playStore) {
                  localStorage.setItem('playStore',true)
                  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
                  const storeURL = isIOS
                    ? "https://moneygo.com.co" // Reemplaza con la URL de tu app en la App Store
                    : "market://details?id=co.com.moneygo.gopay.twa"; // Reemplaza con la URL de tu app en la Play Store
                  window.location.href = storeURL;
                } else {
                  this.isChecking = false; // Ocultar el mensaje de carga si la PWA está instalada
                } 
 
             }else{
               
             }
            
            
        } else {
         
           
            console.log("No estás usando un móvil");
            setTimeout(() => {
              window.location = 'https://moneygo.com.co/reg'
            }, 2000);
            
        }
    

  },
    created(){
      
     
      let navegador = navigator.userAgent;
      this.loadDataUser();
            //this.$store.commit('getMyHistory')
            this.$store.commit('bank')
            this.get()
      
     
      this.removeaud()
      window.onload = function() {
      document.addEventListener("contextmenu", function(e){
     e.preventDefault();
     
     
      }, false);

    } 

   
    }
  };
</script>
  
<style>
*{
  margin: 0;
}

#app {
  
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #161616;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

:root {
  touch-action: pan-x pan-y;
  height: 100% 
}



</style>
